import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
// import styled from 'styled-components';

// const IndexWrapper = styled.main``;
//
// const PostWrapper = styled.div``;

const allPages = ({ data }) => {
  return (
    <Layout isFooterRed={true}>
      <div  style={{
        margin: '100px auto 0 auto',
        maxWidth: 720,
        padding: '0 10px 13px 10px',
        backgroundColor: 'rgba(255,255,255, 0.5)',
      }}>
        { data.allMdx.nodes.map(
          ({ excerpt, frontmatter, slug }) => (
            <Link to={slug} style={{textDecoration: "none"}}>
              <h1>{frontmatter.title}</h1>
              <p text>{excerpt}</p>
            </Link>
          )
        )}
      </div>
    </Layout>
  );
};

export default allPages;

export const query=graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date
        }
        slug
      }
    }
  }
`;