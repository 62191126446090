import React from "react"
import {COLORS} from "../styles/constants";

const Header = () => (
  <header
    id={'main-header'}
    style={{
      position: 'fixed',
      top: '0',
      width: '100%',
      background: COLORS.lightRed,
      zIndex: '1000',
      minHeight: '50px',
      marginBottom: `1.45rem`,
      boxShadow: `
        0 2px 1px rgba(0,0,0,0.09), 
        0 4px 2px rgba(0,0,0,0.09), 
        0 8px 4px rgba(0,0,0,0.09), 
        0 16px 8px rgba(0,0,0,0.09),
        0 32px 16px rgba(0,0,0,0.09)
      `
    }}
  >
    <div style={{
      margin: '15px 20px',
      textAlign: 'center'
    }}>
      <span className="no-underscore">
        <a href="tel:+380507390753" target="_blank" rel="noreferrer noopener" >
          <i className="fa fa-phone"></i>
        </a>
        <a href="mailto:talia.demianiuk@gmail.com" target="_blank" rel="noreferrer noopener">
          <i className="far fa-envelope"></i>
        </a>
        <a href="https://t.me/taliademianiuk" target="_blank" rel="noreferrer noopener">
          <i className="fab fa-telegram-plane"></i>
        </a>

        <a className="btn" href="/">Головна</a>
        <a className="btn" href="/blog/">Блог</a>
      </span>
    </div>
  </header>
);

export default Header
