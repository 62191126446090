/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from "./footer"
import "../styles/default.css"
import headerImage from "../images/header.png";

const Layout = ({ children, isFooterRed }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            fullName
          }
        }
      }
    `}
    render={data => (
      <>
        <div>
          <Header siteTitle={data.title} />
          <main
            style={{
              backgroundImage: `url(${headerImage})`,
              backgroundRepeat: "repeat-y",
              backgroundPosition: "center center"
            }}
          >
            {children}
          </main>
        </div>
        <Footer
          isRed={isFooterRed}
          siteFullName={data.site.siteMetadata.fullName}
          siteTitle={data.site.siteMetadata.title}
        />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isFooterRed: PropTypes.bool,
};

Layout.defaultProps = {
  children: [],
  isFooterRed: true,
}

export default Layout;
