import React from "react"
import PropTypes from "prop-types"

import { COLORS } from "../styles/constants"

const Footer = ({ siteTitle, siteFullName, isRed }) => (
  <footer style={{
    padding: "1rem",
    backgroundColor: isRed ? COLORS.lightRed : 'none'
  }}>
    <div
      style={{
        alignItems: "start",
        display: "grid",
        fontSize: "16px",
        gap: "4px",
        gridTemplateColumns: "55px 190px auto",
        justifyContent: "start",
        padding: "1rem 2rem",
      }}
    >
      <div style={{ color: COLORS.black }}>
        <em style={{ fontWeight: 300 }}>
          © {new Date().getFullYear()}
        </em>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
      }}>
        <em style={{ fontWeight: 300 }}>
          {siteTitle}
        </em>
        <span>
          {siteFullName}
        </span>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  isRed:        PropTypes.bool,
  siteFullName: PropTypes.string,
  siteTitle:    PropTypes.string,
};

Footer.defaultProps = {
  isRed:        false,
  siteFullName: "",
  siteTitle:    "",
};

export default Footer
