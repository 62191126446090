export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  // gray: "#A0A4A6",
  gray: "#838687",
  black: "#060708",
  darkGray: "#838687",
  babyBlue: "#7545d2",
  blue: "#5D41B5",
  darkRed: "#5E1517",
  red: "#912225",
  lightRed: "#f2c1c0",
};

  // ${COLORS.babyBlue},
  // ${COLORS.blue}
export const GRADIENT = `linear-gradient(to top right, 
  ${COLORS.darkRed}, 
  ${COLORS.red}
)`;

export const BORDER_RADIUS = "10px";
